import { Controller } from "@hotwired/stimulus"
import ShowMore from "../helpers/show_more"

export default class extends Controller {
  connect() {
    new ShowMore(this.element, {
      onMoreLess: (type, object) => {}
    });
  }
}

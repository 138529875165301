import type { AccordionItem, AccordionOptions } from 'flowbite';
import { Accordion } from 'flowbite';

const Default: AccordionOptions = {
    alwaysOpen: false,
    activeClasses: 'bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white',
    inactiveClasses: 'text-gray-500 dark:text-gray-400',
    onOpen: () => {},
    onClose: () => {},
    onToggle: () => {},
};

export function initAccordions() {
  document.querySelectorAll('[data-accordion]:not([data-initialized])').forEach(($accordionEl) => {
      const alwaysOpen = $accordionEl.getAttribute('data-accordion');
      const activeClasses = $accordionEl.getAttribute('data-active-classes');
      const inactiveClasses = $accordionEl.getAttribute(
          'data-inactive-classes'
      );

      const items = [] as AccordionItem[];
      $accordionEl
          .querySelectorAll('[data-accordion-target]')
          .forEach(($triggerEl) => {
              $triggerEl.setAttribute('data-initialized', 'true')
              // Consider only items that directly belong to $accordionEl
              // (to make nested accordions work).
              if ($triggerEl.closest('[data-accordion]') === $accordionEl) {
                  const item = {
                      id: $triggerEl.getAttribute('data-accordion-target'),
                      triggerEl: $triggerEl,
                      targetEl: document.querySelector(
                          $triggerEl.getAttribute('data-accordion-target')
                      ),
                      iconEl: $triggerEl.querySelector(
                          '[data-accordion-icon]'
                      ),
                      active:
                          $triggerEl.getAttribute('aria-expanded') === 'true'
                              ? true
                              : false,
                  } as AccordionItem;
                  items.push(item);
              }
          });

      new Accordion($accordionEl as HTMLElement, items, {
          alwaysOpen: alwaysOpen === 'open' ? true : false,
          activeClasses: activeClasses
              ? activeClasses
              : Default.activeClasses,
          inactiveClasses: inactiveClasses
              ? inactiveClasses
              : Default.inactiveClasses,
      } as AccordionOptions);
  });
}

if (typeof window !== 'undefined') {
  window.initAccordions = initAccordions;
}

import { Controller } from "@hotwired/stimulus"
import {
  initFlowbite,
  initCollapses,
  initCarousels,
  initDismisses,
  initDropdowns,
  initModals,
  initDrawers,
  initTabs,
  initTooltips,
  initPopovers,
  initDials,
  initInputCounters,
  initCopyClipboards,
  // initDatepickers
} from "flowbite"
import { initTurboFlowbite } from "../helpers/flowbite"
// import { updateToolbars } from "../helpers/trix"

export default class extends Controller {
  connect() {
    this.switchThemeMode = this.switchThemeMode.bind(this);
    this.setThemeMode = this.setThemeMode.bind(this);
    this.initFlowbite = initFlowbite()
    this.initAccordions = initAccordions()
    this.initCollapses = initCollapses()
    this.initCarousels = initCarousels()
    this.initDismisses = initDismisses()
    this.initDropdowns = initDropdowns()
    this.initModals = initModals()
    this.initDrawers = initDrawers()
    this.initTabs = initTabs()
    this.initTooltips = initTooltips()
    this.initPopovers = initPopovers()
    this.initDials = initDials()
    this.initInputCounters = initInputCounters()
    this.initCopyClipboards = initCopyClipboards()
    const that = this;
    // this.initDatepickers = initDatepickers()

    document.addEventListener("turbo:before-fetch-response", function (event) {
      // console.log("turbo:before-fetch-response", event);
    })

    document.addEventListener("turbo:before-stream-render", (event) => {
      // console.log("turbo:before-stream-render", event);
      // The target frame where the stream is being rendered
      const frameElement = event.target;
      // Alternatively, if you're interested in Turbo Stream elements
      if (frameElement.tagName.toLowerCase() === "turbo-stream") {
        // console.log("Turbo Frame ID:", frameElement.id);
        // Do something with the stream element
        // console.log("Turbo Stream target:", frameElement.getAttribute('target'));
        // console.log("Turbo Stream action:", frameElement.getAttribute("action"));
      }

      const originalRender = event.detail.render
      event.detail.render = function (streamElement) {
        originalRender(streamElement)

        if (frameElement.tagName.toLowerCase() === "turbo-stream") {
          const callback = frameElement.dataset.init;
          // console.log("Turbo Frame Callback:", callback);
          if (![false, 'false'].includes(callback)) {
            let target = document.getElementById(frameElement.getAttribute('target'));
            if (target) {
              // initTurboFlowbite(target)
            }
          }
        }
        // updateToolbars(event)
      }
    })

    // TURBO:FRAME-LOAD fires when a <turbo-frame> element is navigated and
    // finishes loading (fires after turbo:frame-render). The specific
    // <turbo-frame> element is the event target.
    document.addEventListener("turbo:frame-load", (event) => {
      // console.log("turbo:frame-load", event);
    });

    this.setThemeMode()
  }

  switchThemeMode() {
    let mode = localStorage.getItem('color-theme');
    this.setThemeMode(mode == "light" ? "dark" : "light")
  }

  setThemeMode(mode) {
    mode ||= localStorage.getItem('color-theme');
    const $body = document.body;
    const $html = document.documentElement;

    this.setBsThemeMode(mode);
    switch (mode) {
      case 'dark':
          $html.classList.remove('light');
          $html.classList.add('dark');
          $html.dataset.theme = 'dark';
          $html.dataset.bsTheme = 'dark';
          $body.dataset.colorScheme = 'dark';
          $body.dataset.bsTheme = 'dark';
          localStorage.setItem('color-theme', 'dark');
        break;

      default:
        $html.classList.remove('dark');
        $html.classList.add('light');
        $html.dataset.theme = 'light';
        $html.dataset.bsTheme = 'light';
        $body.dataset.colorScheme = 'light';
        $body.dataset.bsTheme = 'light';
        localStorage.setItem('color-theme', 'light');
        break;
    }
  }

  setBsThemeMode(mode) {
    const $darkTarget = document.getElementById('cssDark');
    if (!$darkTarget) return;

    switch (mode) {
      case 'dark':
        $darkTarget.removeAttribute('disabled')
        break;
      default:
        $darkTarget.setAttribute('disabled', true)
        break;
    }
  }
}

import { ValidationController, config } from "hotwired-stimulus-validation"

config({
  focusOnError: true
})

export default class extends ValidationController {
  static targets = ["name", "email", "message", "submit"]

  initialize() {
    this.display = this.display.bind(this)
  }

  display(event) {
    const error = event.target.closest('[data-field-container]').querySelector('.container-error')
    if (error) {
      if (event.errorMessage) {
        error.innerHTML = `${event.target.dataset.title} ${event.errorMessage}`
        error.style.display = 'block'
      } else {
        error.innerHTML = ''
        error.style.display = 'none'
      }

      const that = this;
      that._form.validate().then(function(){
        that.submitTarget.disabled = false
      }).catch(function(error) {
        that.submitTarget.disabled = true
      })
    }
  }
}

import { initAccordions } from './accordion';
// import { initCarousels } from './carousel';
// import { initCopyClipboards } from './clipboard';
// import { initCollapses } from './collapse';
// import { initDials } from './dial';
// import { initDismisses } from './dismiss';
import { initDrawers } from './drawer';
import { initDropdowns } from './dropdown';
// import { initInputCounters } from './input-counter';
import { initModals } from './modal';
import { initPopovers } from './popover';
// import { initTabs } from './tabs';
// import { initTooltips } from './tooltip';
// import { initDatepickers } from './datepicker';

export function initTurboFlowbite(rootElement = document) {
  // initAccordions();
  // initCollapses();
  // initCarousels();
  // initDismisses();
  initDropdowns(rootElement);
  initModals(rootElement);
  initDrawers(rootElement);
  // initTabs();
  // initTooltips();
  initPopovers(rootElement);
  // initDials();
  // initInputCounters();
  // initCopyClipboards();
  // initDatepickers();
}

if (typeof window !== 'undefined') {
  window.initTurboFlowbite = initTurboFlowbite;
}

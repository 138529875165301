import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.select = this.select.bind(this)
    this.save = this.save.bind(this)
    this.submitEnd = this.submitEnd.bind(this)
    this.switchMode = this.switchMode.bind(this)

    this.element.addEventListener("submit", this.save.bind(this));
  }

  select(event) {
    const element = event.currentTarget;

    window.fetch(element.dataset.changeUrl.replace(/__ID__/, element.value), {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    }).then(r => r.text()).then(html => {
      Turbo.renderStreamMessage(html)

      return true
    })
  }

  save(event) {
    event.preventDefault();

    const that = this;
    const searchForm = document.getElementById('searchForm');
    const saveSearchForm = document.getElementById('saveSearchForm');
    const action = saveSearchForm.getAttribute('action');
    const method = saveSearchForm.getAttribute('method') || 'post';
    const modal = event.target.closest('#save-search-modal');
    // Get the CSRF token from the meta tag
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    // Create a new FormData object
    let params = new FormData(saveSearchForm);
    // Iterate over the existing FormData object
    for (let [key, value] of new FormData(searchForm).entries()) {
      if (key !== 'search_terms') {
        params.append(key, value);
      }
    }
    params.append('submit', `save`);

    fetch(action, {
      method: method,
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        'X-CSRF-Token': csrfToken // Include the CSRF token
      },
      body: params
    }).then(r => r.text()).then(html => {
      Turbo.renderStreamMessage(html)
      that.clearForm()
      if (modal) {
        modal.querySelector('button[data-modal-hide="save-search-modal"]').click()
      }
      return true
    })
  }

  switchMode(event) {
    const state = event.currentTarget.value == 'new';
    document.getElementById('query_name').setAttribute('required', state)
    document.getElementById('query_name').setAttribute('disabled', !state)
  }

  submitEnd(event) {
    const response = event.detail.fetchResponse.response;

    if (response.status == 200) {
      this.clearForm()
      this.element.querySelector('button[data-modal-hide="save-search-modal"]').click()
    }
  }

  toggleSubmitButton(button) {
    const originalContent = button.innerHTML;
    const loadingContent = button.getAttribute('data-turbo-submits-with');
    button.setAttribute('data-turbo-submits-with', originalContent);

    // Set the button content to the loading state
    button.innerHTML = loadingContent;
    button.disabled = !button.disabled; // Optionally disable the button
  }

  clearForm() {
    const elements = this.element.querySelectorAll('select, input');
    elements.forEach(function(element) {
      if (element.type == 'checkbox' || element.type == 'radio') {
        element.checked = false; // Uncheck checkboxes and radio buttons
      } else {
        element.value = ''; // Clear the value for other input types and select elements
      }
    });
  }
}


import type { InstanceOptions, ModalOptions } from 'flowbite';
import { Modal, ModalInterface } from 'flowbite';

const instances = FlowbiteInstances;

const Default: ModalOptions = {
    placement: 'center',
    backdropClasses: 'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
    backdrop: 'dynamic',
    closable: true,
    onHide: () => {},
    onShow: () => {},
    onToggle: () => {},
};

const DefaultInstanceOptions: InstanceOptions = {
    id: null,
    override: true,
};

export function initModals(rootElement?: Element | HTMLElement | Document) {
  rootElement ||= document;
  // initiate modal based on data-modal-target
  rootElement.querySelectorAll('[data-modal-target]').forEach(($triggerEl) => {
      $triggerEl.setAttribute('data-initialized', 'true')
      const modalId = $triggerEl.getAttribute('data-modal-target');
      const $modalEl = document.getElementById(modalId);

      if ($modalEl) {
          const placement = $modalEl.getAttribute('data-modal-placement');
          const backdrop = $modalEl.getAttribute('data-modal-backdrop');
          new Modal(
              $modalEl as HTMLElement,
              {
                  placement: placement ? placement : Default.placement,
                  backdrop: backdrop ? backdrop : Default.backdrop,
              } as ModalOptions
          );
      } else {
          console.error(
              `Modal with id ${modalId} does not exist. Are you sure that the data-modal-target attribute points to the correct modal id?.`
          );
      }
  });

  // toggle modal visibility
  rootElement.querySelectorAll('[data-modal-toggle]').forEach(($triggerEl) => {
      $triggerEl.setAttribute('data-initialized', 'true')
      const modalId = $triggerEl.getAttribute('data-modal-toggle');
      const $modalEl = document.getElementById(modalId);

      if ($modalEl) {
          const modal: ModalInterface = instances.getInstance(
              'Modal',
              modalId
          );

          if (modal) {
              const toggleModal = () => {
                  modal.toggle();
              };
              $triggerEl.addEventListener('click', toggleModal);
              modal.addEventListenerInstance(
                  $triggerEl as HTMLElement,
                  'click',
                  toggleModal
              );
          } else {
              console.error(
                  `Modal with id ${modalId} has not been initialized. Please initialize it using the data-modal-target attribute.`
              );
          }
      } else {
          console.error(
              `Modal with id ${modalId} does not exist. Are you sure that the data-modal-toggle attribute points to the correct modal id?`
          );
      }
  });

  // show modal on click if exists based on id
  rootElement.querySelectorAll('[data-modal-show]').forEach(($triggerEl) => {
      $triggerEl.setAttribute('data-initialized', 'true')
      const modalId = $triggerEl.getAttribute('data-modal-show');
      const $modalEl = document.getElementById(modalId);

      if ($modalEl) {
          const modal: ModalInterface = instances.getInstance(
              'Modal',
              modalId
          );

          if (modal) {
              const showModal = () => {
                  modal.show();
              };
              $triggerEl.addEventListener('click', showModal);
              modal.addEventListenerInstance(
                  $triggerEl as HTMLElement,
                  'click',
                  showModal
              );
          } else {
              console.error(
                  `Modal with id ${modalId} has not been initialized. Please initialize it using the data-modal-target attribute.`
              );
          }
      } else {
          console.error(
              `Modal with id ${modalId} does not exist. Are you sure that the data-modal-show attribute points to the correct modal id?`
          );
      }
  });

  // hide modal on click if exists based on id
  rootElement.querySelectorAll('[data-modal-hide]').forEach(($triggerEl) => {
      $triggerEl.setAttribute('data-initialized', 'true')
      const modalId = $triggerEl.getAttribute('data-modal-hide');
      const $modalEl = document.getElementById(modalId);

      if ($modalEl) {
          const modal: ModalInterface = instances.getInstance(
              'Modal',
              modalId
          );

          if (modal) {
              const hideModal = () => {
                  modal.hide();
              };
              $triggerEl.addEventListener('click', hideModal);
              modal.addEventListenerInstance(
                  $triggerEl as HTMLElement,
                  'click',
                  hideModal
              );
          } else {
              console.error(
                  `Modal with id ${modalId} has not been initialized. Please initialize it using the data-modal-target attribute.`
              );
          }
      } else {
          console.error(
              `Modal with id ${modalId} does not exist. Are you sure that the data-modal-hide attribute points to the correct modal id?`
          );
      }
  });
}

if (typeof window !== 'undefined') {
  window.initModals = initModals;
}

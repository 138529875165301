import CheckboxSelectAll from '@stimulus-components/checkbox-select-all'

export default class extends CheckboxSelectAll {
  static targets = ["checkbox", "checkboxAll", "signature", "receiver", "message", "sender", "user", "disabledButton"]

  connect() {
    this.initComposeModal = this.initComposeModal.bind(this)
    this.closeCompose = this.closeCompose.bind(this)
    this.composeModal = this.initComposeModal()
  }

  initComposeModal() {
    const that = this;
    // set the modal menu element
    const $targetEl = document.getElementById('composeModal');

    // options with default values
    const options = {
      placement: 'bottom-right',
      backdrop: 'dynamic',
      backdropClasses:
        'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
      closable: true,
      onHide: () => {
      },
      onShow: () => {
      },
      onToggle: () => {
      },
    };

    // instance options object
    const instanceOptions = {
      id: 'composeModal',
      override: true
    };

    return new Modal($targetEl, options, instanceOptions);
  }

  compose(event) {
    console.log(event.currentTarget)
    const data = event.currentTarget.dataset
    this.messageTarget.value = this.messageTarget.dataset.body.replace(/__NAME__/i, data.name)
    this.senderTarget.value = this.signatureTargets.filter(radio => radio.checked)[0].value
    this.receiverTarget.innerHTML = data.email
    this.userTarget.value = data.id
    this.composeModal.show()
  }

  closeCompose() {
    this.composeModal.hide()
  }

  selectSender(event) {
    if (event.currentTarget.checked) {
      document.getElementById('selectedSender').innerHTML = event.currentTarget.value
    }
  }

  toggle(e) {
    super.toggle(e)
    this.toggleDisabledButton()
  }

  toggleDisabledButton() {
    const that = this;
    const selectedForm = document.getElementById('selectedForm')

    if (this.checked.length > 0) {
      this.checked.forEach(e => this.handleCheckboxChange(e));
      that.disabledButtonTarget.disabled = false;
      that.disabledButtonTarget.classList.remove(...that.disabledButtonTarget.dataset.inactiveClass.split(' '));
      that.disabledButtonTarget.classList.add(...that.disabledButtonTarget.dataset.activeClass.split(' '));
    } else {
      selectedForm.querySelectorAll('[name="user_ids[]"]').forEach(e => e.remove());
      that.disabledButtonTarget.disabled = true;
      that.disabledButtonTarget.classList.remove(...that.disabledButtonTarget.dataset.activeClass.split(' '));
      that.disabledButtonTarget.classList.add(...that.disabledButtonTarget.dataset.inactiveClass.split(' '));
    }
  }

  handleCheckboxChange(checkbox) {
    const form = document.getElementById('selectedForm');

    // Check if the checkbox is checked
    if (checkbox.checked) {
      // Create a hidden input
      const hiddenInput = document.createElement('input');
      hiddenInput.type = 'hidden';
      hiddenInput.name = "user_ids[]";  // Unique name for the hidden field
      hiddenInput.value = checkbox.value;
      hiddenInput.id = checkbox.value + '_hidden'; // Unique ID

      // Append the hidden input to the form
      form.appendChild(hiddenInput);
    } else {
      // Remove the hidden input if the checkbox is unchecked
      const hiddenInput = document.getElementById(checkbox.value + '_hidden');
      if (hiddenInput) {
        form.removeChild(hiddenInput);
      }
    }
  }
}

import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="search---modal"
export default class extends Controller {
  static targets = ["modal", "overlay"];

  connect() {
    this.submitEnd = this.submitEnd.bind(this)
    this.add = this.add.bind(this)
  }

  show(event) {
  }

  close(event) {
  }

  add(event) {
    const that = this;
    that.toggleSubmitButton(event.target)
    const form = event.target.closest('form');
    const searchForm = document.getElementById('searchForm');
    const action = form.getAttribute('action');
    const method = form.getAttribute('method') || 'post';
    const modal = event.target.closest('#bulk-add-modal');
    let params = new FormData(form);

    // Create a new FormData object
    let filteredParams = new FormData(searchForm);
    // Iterate over the existing FormData object
    for (let [key, value] of filteredParams.entries()) {
      if (key !== 'search_terms') {
        params.append(key, value);
      }
    }

    const selectedValues = this.selectedCheckboxes()
    // Append each selected value to the FormData object
    selectedValues.forEach(element => {
      params.append(element.getAttribute('name'), element.value);
      element.classList.remove('selected_ids');
    });

    // Get all checkboxes with the name 'existed_ids[]'
    const existedInputs = document.getElementsByClassName('existed_ids');
    Array.from(existedInputs).forEach(element => {
      params.append(element.getAttribute('name'), element.value);
    })

    params.append('commit', event.target.dataset.commit);

    fetch(action, {
      method: method,
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
      body: params
    }).then(r => r.text()).then(html => {
      Turbo.renderStreamMessage(html)
      that.toggleSubmitButton(event.target)
      that.clearChecked(selectedValues)
      that.dispatch("change", { detail: { field_name: 'seleted_ids', field_value: selectedValues } });
      if (modal) {
        modal.querySelector('[data-modal-hide]').click()
      }
      return true
    })
  }

  toggleSubmitButton(button) {
    const originalContent = button.innerHTML;
    const loadingContent = button.getAttribute('data-turbo-submits-with');
    button.setAttribute('data-turbo-submits-with', originalContent);

    // Set the button content to the loading state
    button.innerHTML = loadingContent;
    button.disabled = !button.disabled; // Optionally disable the button
  }

  showModal(modalId) {
  }

  hideModal(modalId) {
  }

  submitEnd(event) {
    const response = event.detail.fetchResponse.response;
    this.dispatch("change", { detail: event.detail });

    if (response.status == 200) {
      this.clearForm()
      this.element.querySelector('[data-modal-hide]').click()
    }
  }

  selectedCheckboxes() {
    // Get all checkboxes with the name 'selected_ids[]'
    const checkboxes = document.getElementsByClassName('selected_ids');
    // Map over the NodeList to get the values of checked checkboxes
    return Array.from(checkboxes).filter(checkbox => checkbox.checked);
  }

  clearChecked(selectedValues) {
    const totalCheck = document.getElementById('select-all-checkbox')
    if (totalCheck) {
      totalCheck.click()
    } else if (selectedValues.length > 0) {
      selectedValues.forEach(element => {
        element.checked = false;
      })
    }

    document.getElementById('addForm').classList.remove('is-selected')
  }

  clearForm() {
    const elements = this.element.querySelectorAll('select, input');
    elements.forEach(function(element) {
      if (element.type == 'checkbox' || element.type == 'radio') {
        element.checked = false; // Uncheck checkboxes and radio buttons
      } else {
        element.value = ''; // Clear the value for other input types and select elements
      }
    });
  }
}

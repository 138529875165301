import type { DrawerOptions, PlacementClasses, InstanceOptions, EventListenerInstance } from 'flowbite';

const instances = FlowbiteInstances;

const Default: DrawerOptions = {
  placement: 'left',
  bodyScrolling: false,
  backdrop: true,
  edge: false,
  edgeOffset: 'bottom-[60px]',
  backdropClasses: 'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-30',
  onShow: () => {},
  onHide: () => {},
  onToggle: () => {},
};

const DefaultInstanceOptions: InstanceOptions = {
  id: null,
  override: true,
};

export function initDrawers(rootElement?: Element | HTMLElement | Document) {
  rootElement ||= document;
  rootElement.querySelectorAll('[data-drawer-target]').forEach(($triggerEl) => {
      $triggerEl.setAttribute('data-initialized', 'true');
      // mandatory
      const drawerId = $triggerEl.getAttribute('data-drawer-target');
      const $drawerEl = document.getElementById(drawerId);

      if ($drawerEl) {
          const placement = $triggerEl.getAttribute('data-drawer-placement');
          const bodyScrolling = $triggerEl.getAttribute(
              'data-drawer-body-scrolling'
          );
          const backdrop = $triggerEl.getAttribute('data-drawer-backdrop');
          const edge = $triggerEl.getAttribute('data-drawer-edge');
          const edgeOffset = $triggerEl.getAttribute(
              'data-drawer-edge-offset'
          );

          new Drawer($drawerEl, {
              placement: placement ? placement : Default.placement,
              bodyScrolling: bodyScrolling
                  ? bodyScrolling === 'true'
                      ? true
                      : false
                  : Default.bodyScrolling,
              backdrop: backdrop
                  ? backdrop === 'true'
                      ? true
                      : false
                  : Default.backdrop,
              edge: edge ? (edge === 'true' ? true : false) : Default.edge,
              edgeOffset: edgeOffset ? edgeOffset : Default.edgeOffset,
          } as DrawerOptions);
      } else {
          console.error(
              `Drawer with id ${drawerId} not found. Are you sure that the data-drawer-target attribute points to the correct drawer id?`
          );
      }
  });

  rootElement.querySelectorAll('[data-drawer-toggle]').forEach(($triggerEl) => {
      $triggerEl.setAttribute('data-initialized', 'true');
      const drawerId = $triggerEl.getAttribute('data-drawer-toggle');
      const $drawerEl = document.getElementById(drawerId);

      if ($drawerEl) {
          const drawer: DrawerInterface = instances.getInstance(
              'Drawer',
              drawerId
          );

          if (drawer) {
              const toggleDrawer = () => {
                  drawer.toggle();
              };
              $triggerEl.addEventListener('click', toggleDrawer);
              drawer.addEventListenerInstance(
                  $triggerEl as HTMLElement,
                  'click',
                  toggleDrawer
              );
          } else {
              console.error(
                  `Drawer with id ${drawerId} has not been initialized. Please initialize it using the data-drawer-target attribute.`
              );
          }
      } else {
          console.error(
              `Drawer with id ${drawerId} not found. Are you sure that the data-drawer-target attribute points to the correct drawer id?`
          );
      }
  });

  rootElement
      .querySelectorAll('[data-drawer-dismiss], [data-drawer-hide]')
      .forEach(($triggerEl) => {
          $triggerEl.setAttribute('data-initialized', 'true');
          const drawerId = $triggerEl.getAttribute('data-drawer-dismiss')
              ? $triggerEl.getAttribute('data-drawer-dismiss')
              : $triggerEl.getAttribute('data-drawer-hide');
          const $drawerEl = document.getElementById(drawerId);

          if ($drawerEl) {
              const drawer: DrawerInterface = instances.getInstance(
                  'Drawer',
                  drawerId
              );

              if (drawer) {
                  const hideDrawer = () => {
                      drawer.hide();
                  };
                  $triggerEl.addEventListener('click', hideDrawer);
                  drawer.addEventListenerInstance(
                      $triggerEl as HTMLElement,
                      'click',
                      hideDrawer
                  );
              } else {
                  console.error(
                      `Drawer with id ${drawerId} has not been initialized. Please initialize it using the data-drawer-target attribute.`
                  );
              }
          } else {
              console.error(
                  `Drawer with id ${drawerId} not found. Are you sure that the data-drawer-target attribute points to the correct drawer id`
              );
          }
      });

    rootElement.querySelectorAll('[data-drawer-show]').forEach(($triggerEl) => {
      $triggerEl.setAttribute('data-initialized', 'true');
      const drawerId = $triggerEl.getAttribute('data-drawer-show');
      const $drawerEl = document.getElementById(drawerId);

      if ($drawerEl) {
          const drawer: DrawerInterface = instances.getInstance(
              'Drawer',
              drawerId
          );

          if (drawer) {
              const showDrawer = () => {
                  drawer.show();
              };
              $triggerEl.addEventListener('click', showDrawer);
              drawer.addEventListenerInstance(
                  $triggerEl as HTMLElement,
                  'click',
                  showDrawer
              );
          } else {
              console.error(
                  `Drawer with id ${drawerId} has not been initialized. Please initialize it using the data-drawer-target attribute.`
              );
          }
      } else {
          console.error(
              `Drawer with id ${drawerId} not found. Are you sure that the data-drawer-target attribute points to the correct drawer id?`
          );
      }
  });
}

if (typeof window !== 'undefined') {
  window.initDrawers = initDrawers;
}

import type { DropdownOptions, InstanceOptions } from 'flowbite';
import { Dropdown } from 'flowbite';

const Default: DropdownOptions = {
  placement: 'bottom',
  triggerType: 'click',
  offsetSkidding: 0,
  offsetDistance: 10,
  delay: 300,
  ignoreClickOutsideClass: false,
  onShow: () => {},
  onHide: () => {},
  onToggle: () => {},
};

const DefaultInstanceOptions: InstanceOptions = {
  id: null,
  override: true,
};

export function initDropdowns(rootElement?: Element | HTMLElement | Document) {
  rootElement ||= document;
  rootElement.querySelectorAll('[data-dropdown-toggle]')
      .forEach(($triggerEl) => {
          $triggerEl.setAttribute('data-initialized', 'true');
          const dropdownId = $triggerEl.getAttribute('data-dropdown-toggle');
          const $dropdownEl = document.getElementById(dropdownId);

          if ($dropdownEl) {
              const placement = $triggerEl.getAttribute(
                  'data-dropdown-placement'
              );
              const offsetSkidding = $triggerEl.getAttribute(
                  'data-dropdown-offset-skidding'
              );
              const offsetDistance = $triggerEl.getAttribute(
                  'data-dropdown-offset-distance'
              );
              const triggerType = $triggerEl.getAttribute(
                  'data-dropdown-trigger'
              );
              const delay = $triggerEl.getAttribute('data-dropdown-delay');
              const ignoreClickOutsideClass = $triggerEl.getAttribute(
                  'data-dropdown-ignore-click-outside-class'
              );


              new Dropdown(
                  $dropdownEl as HTMLElement,
                  $triggerEl as HTMLElement,
                  {
                      placement: placement ? placement : Default.placement,
                      triggerType: triggerType
                          ? triggerType
                          : Default.triggerType,
                      offsetSkidding: offsetSkidding
                          ? parseInt(offsetSkidding)
                          : Default.offsetSkidding,
                      offsetDistance: offsetDistance
                          ? parseInt(offsetDistance)
                          : Default.offsetDistance,
                      delay: delay ? parseInt(delay) : Default.delay,
                      ignoreClickOutsideClass: ignoreClickOutsideClass
                          ? ignoreClickOutsideClass
                          : Default.ignoreClickOutsideClass,
                  } as DropdownOptions
              );
          } else {
              console.error(
                  `The dropdown element with id "${dropdownId}" does not exist. Please check the data-dropdown-toggle attribute.`
              );
          }
      });
}

if (typeof window !== 'undefined') {
  window.initDropdowns = initDropdowns;
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    hiddenClass: String,
    time: Number,
  }

  initialize() {
    if (this.timeValue) {
      this.timer()
    }
  }

  close() {
    if (this.hiddenClassValue === '') {
      this.element.remove()
    } else {
      this.element.classList.add(this.hiddenClassValue)
    }
  }

  timer() {
    setTimeout(() => { this.close() }, this.timeValue)
  }
}

import CheckboxSelectAll from '@stimulus-components/checkbox-select-all'

export default class extends CheckboxSelectAll {
  static targets = ["checkbox", "checkboxAll", "fromList", "toList"]

  connect() {
    super.connect()
  }

  checkboxTargetConnected(checkbox) {
    checkbox.addEventListener('change', this.checkboxOnChange.bind(this))
  }

  toggleButton(button) {
    if (button) {
      if (this.checked.length > 0) {
        button.classList.remove("opacity-65", "cursor-not-allowed")
        button.disabled = false
      } else {
        button.classList.add("opacity-65", "cursor-not-allowed")
        button.disabled = true
      }
    }
  }

  added(e) {
    if (e.detail.success) {
      e.target.closest('#addListModal').querySelector('[data-modal-hide]').click()
    }
  }

  merged(e) {
    if (e.detail.success) {
      e.target.closest('#mergeListModal').querySelector('[data-modal-hide]').click()
    }
  }

  toListTargetConnected(select){
    select.addEventListener('change', this.cascaded.bind(this))
  }

  fromListTargetConnected(select){
    select.addEventListener('change', this.cascaded.bind(this))
  }

  cascaded(e) {
    const $cascadedList = e.target.id == 'from_list_id' ? this.toListTarget : this.fromListTarget
    if($cascadedList) {
      const $currentSelected = $cascadedList.querySelector('option[disabled]')
      if($currentSelected) {
        $currentSelected.disabled = false
      }

      const $cascadedSelected = $cascadedList.querySelector(`option[value="${e.target.value}"]`)
      if ($cascadedSelected) {
        $cascadedSelected.disabled = true
      }
    }
  }

  checkboxOnChange(event) {
    const $formTarget = document.querySelector(event.currentTarget.dataset.formTarget)

    if ($formTarget) {
      const signedId = event.target.value
      const $submitButton = $formTarget.querySelector(`[type="submit"]`)
      this.toggleButton($submitButton)

      if (event.target.checked) {
        const hiddenField = document.createElement("input")
        hiddenField.setAttribute("type", "hidden")
        hiddenField.setAttribute("name", event.target.name)
        hiddenField.setAttribute("value", signedId)
        hiddenField.setAttribute("data-selected-id", signedId)
        $formTarget.appendChild(hiddenField)
      } else {
        const hiddenField = $formTarget.querySelector(`input[type="hidden"][data-selected-id="${signedId}"]`)
        if (hiddenField) {
          hiddenField.remove()
        }
      }
    }
  }
}

import type { PopoverOptions, InstanceOptions } from 'flowbite';
import { PopoverInterface } from 'flowbite';

const instances = FlowbiteInstances;

const Default: PopoverOptions = {
    placement: 'top',
    offset: 10,
    triggerType: 'hover',
    onShow: () => {},
    onHide: () => {},
    onToggle: () => {},
};

const DefaultInstanceOptions: InstanceOptions = {
    id: null,
    override: true,
};

export function initPopovers(rootElement?: Element | HTMLElement | Document) {
  rootElement ||= document;
  rootElement.querySelectorAll('[data-popover-target]').forEach(($triggerEl) => {
      const popoverID = $triggerEl.getAttribute('data-popover-target');
      const $popoverEl = document.getElementById(popoverID);

      if ($popoverEl) {
          const triggerType = $triggerEl.getAttribute('data-popover-trigger');
          const placement = $triggerEl.getAttribute('data-popover-placement');
          const offset = $triggerEl.getAttribute('data-popover-offset');

          new Popover(
              $popoverEl as HTMLElement,
              $triggerEl as HTMLElement,
              {
                  placement: placement ? placement : Default.placement,
                  offset: offset ? parseInt(offset) : Default.offset,
                  triggerType: triggerType
                      ? triggerType
                      : Default.triggerType,
              } as PopoverOptions
          );
      } else {
          console.error(
              `The popover element with id "${popoverID}" does not exist. Please check the data-popover-target attribute.`
          );
      }
  });
}

if (typeof window !== 'undefined') {
  window.initPopovers = initPopovers;
}

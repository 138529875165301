import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage";

class Uploader {
  constructor(file, url, thumbnail) {
    this.thumbnail = thumbnail
    this.file = file
    this.url = url
    this.directUpload = new DirectUpload(this.file, this.url, this)
    this.directUploadDidProgress = this.directUploadDidProgress.bind(this)
  }

  upload() {
    return new Promise((resolve, reject) => {
      this.directUpload.create((error, blob) => {
        if (error) {
          // Handle the error
          reject(error)
        } else {
          // Add an appropriately-named hidden input to the form
          // with a value of blob.signed_id
          resolve(blob)
        }
      })
    })
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", this.directUploadDidProgress.bind(this))
  }

  directUploadDidProgress(event) {
    // Use event.loaded and event.total to update the progress bar
    if (event.lengthComputable) {
      const progressBar = this.thumbnail.querySelector('.progress-bar')
      const percentComplete = (event.loaded / event.total) * 100;
      progressBar.style.width = `${percentComplete}%`;
    }
  }

  addHiddenField(blob) {
    const hiddenField = document.createElement("input")
    hiddenField.setAttribute("type", "hidden")
    hiddenField.setAttribute("name", "ticket[images][]")
    hiddenField.setAttribute("value", blob.signed_id)
    this.element.appendChild(hiddenField)
  }
}

export default class extends Controller {
  static targets = ["previews", "input"];

  connect() {
    this.uploadedFiles = []
    this.deleteAttachment = this.deleteAttachment.bind(this)
  }

  upload(event) {
    const files = Array.from(event.target.files)
    const max = event.target.dataset.max || 3
    const fileSize = event.target.dataset.fileSize || 10 * 1024 * 1024

    if (this.uploadedFiles.length + files.length > max) {
      alert("You can only upload up to 1 images.")
      return
    }

    files.forEach(file => {
      if (file.size > fileSize) { // 10MB in bytes
        alert(`The file ${file.name} exceeds the 10MB size limit.`)
      } else {
        this.uploadFile(file, this.createThumbnail(file));
      }
    })

    this.inputTarget.value = ''
  }

  createThumbnail(file) {
    const reader = new FileReader();
    const thumbnail = document.createElement("div");

    reader.onload = (event) => {
      thumbnail.classList.add("thumbnail-container", "relative", "mb-2", "opacity-85");
      thumbnail.innerHTML = `
        <img src="${event.target.result}" alt="Preview" class="thumbnail h-auto max-h-32 rounded-lg max-w-full" />
        <div class="progress-container absolute bottom-0 w-full bg-gray-200 h-1.5 dark:bg-gray-700">
          <div class="progress-bar bg-blue-600 h-1.5 dark:bg-blue-500" style="width: 0%;"></div>
        </div>
      `;
      this.previewsTarget.appendChild(thumbnail);
    };
    reader.readAsDataURL(file);

    return thumbnail;
  }

  uploadFile(file, thumbnail) {
    const that = this;
    new Uploader(file, this.inputTarget.dataset.directUploadUrl, thumbnail).upload().then((blob) => {
      that.showDeleteButton(blob, thumbnail)
      that.addHiddenField(blob)
      thumbnail.querySelector('.progress-container').remove()
      thumbnail.classList.remove('opacity-85')
      that.uploadedFiles.push(blob.signed_id)
    })
  }

  addHiddenField(blob) {
    const hiddenField = document.createElement("input")
    hiddenField.setAttribute("type", "hidden")
    hiddenField.setAttribute("name", this.inputTarget.name)
    hiddenField.setAttribute("value", blob.signed_id)
    hiddenField.setAttribute("data-signed-id", blob.signed_id)
    this.element.appendChild(hiddenField)
  }

  showDeleteButton(blob, thumbnail) {
    if (thumbnail) {
      const deleteButton = document.createElement("button");
      deleteButton.type = 'button'
      deleteButton.innerHTML = `<svg class="w-4 h-4 text-red-500 hover:text-white" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"></path></svg>`
      deleteButton.classList.add("delete-button", "absolute", "inline-flex", "items-center", "justify-center", "bg-white", "w-6", "h-6", "border-2", "border-white", "rounded-full", "-top-2", "-end-2", "hover:bg-red-500")
      deleteButton.dataset.signedId = blob.signed_id
      deleteButton.addEventListener("click", this.deleteAttachment.bind(this))
      thumbnail.appendChild(deleteButton);
    }
  }

  deleteAttachment(event) {
    const signedId = event.currentTarget.dataset.signedId
    this.uploadedFiles = this.uploadedFiles.filter(id => id !== signedId)

    const hiddenField = this.element.querySelector(`input[type="hidden"][data-signed-id="${signedId}"]`)
    if (hiddenField) {
      hiddenField.remove()
    }

    const thumbnailContainer = event.currentTarget.closest(".thumbnail-container")
    if (thumbnailContainer) {
      thumbnailContainer.remove()
    }
  }
}

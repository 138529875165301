import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
  static targets = ['input', 'submit']

  connect() {
    this.submitEnd = this.submitEnd.bind(this)
    this.galleryTarget = this.element.querySelector('#previews')
    this.toggleButtonSubmit = this.toggleButtonSubmit.bind(this)
  }

  resetForm() {
    this.galleryTarget.innerHTML = ''
  }

  inputTargetConnected(input) {
    input.addEventListener('change', this.toggleButtonSubmit.bind(this));
    input.addEventListener('input', this.toggleButtonSubmit.bind(this));
  }

  toggleButtonSubmit() {
    const that = this;

    if (that.inputTarget.value.trim() !== '' && that.inputTarget.value.trim().length > that.inputTarget.getAttribute('minlength')) { // Check if input is not empty
      that.submitTarget.disabled = false; // Enable button
      that.submitTarget.classList.remove(...that.submitTarget.dataset.inactiveClass.split(' ')); // Remove inactive classes
      that.submitTarget.classList.add(...that.submitTarget.dataset.activeClass.split(' ')); // Add active classes
    } else {
      that.submitTarget.disabled = true; // Disable button
      that.submitTarget.classList.remove(...that.submitTarget.dataset.activeClass.split(' ')); // Remove active classes
      that.submitTarget.classList.add(...that.submitTarget.dataset.inactiveClass.split(' ')); // Add inactive classes
    }
  }

  submitEnd() {
    this.inputTarget.value = ''
    this.galleryTarget.innerHTML = ''
    this.toggleAttachmentContainer()
    this.toggleButtonSubmit()
  }
}
